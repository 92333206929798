import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'

const Tag = styled.span`
  ${tw`inline-flex items-center text-black font-bold`}
`

const Separator = styled.span`
  ${tw`inline-block font-bold mx-2 text-5xl`}
  height: 0;
  color: ${props => props.theme.ally};
  line-height: 1.5rem;
`

const TagList = props => {
  const { tags = [], separator = '*' } = props
  return (
    <>
      {tags.map((tag, i) => (
        <Tag key={tag}>
          <span>{tag}</span>
          {i !== tags.length - 1 && <Separator>{separator}</Separator>}
        </Tag>
      ))}
    </>
  )
}

TagList.defaultProps = {
  tags: [],
  separator: '*',
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  separator: PropTypes.string,
}

export default TagList
