import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screens } from '../../tailwind'
import { ArrowLink } from '../elements/Links'

const Wrapper = styled.div`
  ${tw`flex items-center justify-center m-auto font-bold text-lg`}
  margin-top: 8rem;
  margin-bottom: 16rem;

  @media (min-width: ${screens.lg}) {
    margin-bottom: 20rem;
  }
`

const NextText = styled.div`
  ${tw`hidden md:block mr-8`};
`

const NextProject = ({ children, ...rest }) => (
  <Wrapper>
    <NextText>Next project</NextText>
    <ArrowLink cover {...rest}>
      {children}
    </ArrowLink>
  </Wrapper>
)

NextProject.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NextProject
