import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screens } from '../../tailwind'

// Titles & text
export const ProjectTitle = styled.h1`
  ${tw`mt-0 mb-8 text-black font-black text-5xl lg:text-6xl`};
`

export const ProjectDate = styled.p`
  ${tw`mb-1 text=base lg:text-lg font-bold`};
  color: ${props => props.theme.ally};
`

export const ProjectDescription = styled.p`
  ${tw`text-grey-darker text-xl lg:text-2xl leading-normal`};
  max-width: 40rem;
`

export const ProjectRow = styled.div`
  margin: 4rem auto;

  @media (min-width: ${screens.md}) {
    display: flex;
    flex-flow: row wrap;
    margin: 10rem auto;
  }
`

export const ProjectRowPersona = styled(ProjectRow)`
  margin: 4rem auto 0;

  @media (min-width: ${screens.md}) {
    display: flex;
    justify-content: space-around;
    margin: 8rem auto 0rem;
  }
`

export const ProjectSection = styled.div`
  ${tw`lg:px-16`}

  @media (min-width: ${screens.lg}) {
    flex: 1 1 50%;
    max-width: 50%;
    margin-top: ${props => (props.mt ? props.mt : '0')};

    &:first-child {
      padding-left: 0;
    }

    &:last-child:not(:first-child) {
      padding-right: 0;
    }
  }
`

export const ProjectSectionTitle = styled.h2`
  ${tw`mt-0 mb-8 text-2xl font-bold`};
  color: ${props => props.theme.ally};
`

export const ProjectSectionText = styled.div`
  ${tw`mb-4 md:mb-8 text-black text-base leading-normal`};

  ul {
    ${tw`list-reset`}
  }
`

// Containers
export const ProjectInner = styled.div`
  ${tw`m-auto px-8 md:px-16`}
  max-width: 1200px;
`

export const HeaderBigBox = styled.div`
  ${tw`w-full pt-32 pb-16`};
  background: linear-gradient(${props => props.theme.accent}, 70%, transparent 70%);
`

export const BigBox = styled.div`
  ${tw`p-4`};
  position: relative;
  background: ${props => props.theme.accent};
`

export const BigBoxFull = styled(BigBox)`
  ${tw`mb-4 md:mb-8`};

  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 4rem;
    background: ${props => props.theme.accent};
  }

  ::before {
    left: -4rem;
  }

  ::after {
    right: -4rem;
  }
`

export const ImageContainerFull = styled.div`
  ${tw`m-auto`};
  width: 100%;
  max-width: 1980px;
`

export const ImageContainer = styled.div`
  ${tw`relative m-auto`};
  width: 100%;
  max-width: 700px;

  & > img {
    width: 100%;
  }
`

export const ImageGrid = styled.div`
  ${tw`flex flex-wrap items-center justify-center`}

  & > img {
    flex: 0 0 50%;
    width: 220px;
    padding: 1rem;
  }
`

export const ProjectHeader = styled.div`
  margin-bottom: 7rem;
`

export const ProjectList = styled.ol`
  ${tw`text-lg lg:text-xl my-0`};
  color: ${props => props.theme.ally};
  padding-inline-start: 1.25rem;

  > li {
    ${tw`mb-4`};
  }
`

export const ProjectCallout = styled.div`
  ${tw`mb-4 md:mb-8 text-lg lg:text-xl leading-normal`};
  color: ${props => props.theme.ally};
`

export const ImageCaption = styled.div`
  ${tw`text-xs text-grey-darker mt-4`}
`
export const BulletList = styled.ul`
  margin: 0;
  padding-inline-start: 1.5rem;

  & > li {
    padding-left: 0.25rem;
    margin: 0 0 0.5rem;
  }
`
