import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Img from 'gatsby-image'
import { screens } from '../../tailwind'

// SVGs
import saysSvg from '../images/persona/says.svg'
import thinksSvg from '../images/persona/thinks.svg'
import feelsSvg from '../images/persona/feels.svg'
import doesSvg from '../images/persona/does.svg'

const Container = styled.div`
  ${tw`flex flex-col md:flex-row`};
  width: 100%;
  box-shadow: 0 9px 12px rgba(0, 0, 0, 0.15);
`

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  min-height: 350px;
  background-color: ${props => props.theme.accent};

  @media (min-width: ${screens.md}) {
    width: 100%;
    min-width: 250px;
    max-width: 400px;
  }
`

const Details = styled.div`
  ${tw`flex flex-col justify-center px-8 lg:px-16 py-8 text-base `};
`

const DetailsHeader = styled.div`
  ${tw`lg:flex items-center justify-between pb-8 lg:pb-12`};
`

const DetailsGrid = styled.div`
  ${tw`flex flex-wrap justify-between grey-lightest`};
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${screens.md}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  @media (min-width: ${screens.lg}) {
    grid-gap: 3rem;
  }
`

const Name = styled.span`
  ${tw`mb-4 md:mb-0 font-bold text-2xl text-grey-darker`}
`

const DetailTitle = styled.div`
  ${tw`flex items-center pb-4 font-black text-lg text-grey-darkest`}
  img {
    height: 1.5rem;
    margin-right: 1rem;
  }
`

const icons = {
  says: saysSvg,
  thinks: thinksSvg,
  feels: feelsSvg,
  does: doesSvg,
}

const Persona = props => {
  const { name, feels, says, thinks, does, age, job, imageUrl, ...rest } = props
  const details = {
    feels,
    does,
    thinks,
    says,
  }
  return (
    <Container {...rest}>
      <ImageContainer>
        <Img fixed={imageUrl} />
      </ImageContainer>
      <Details>
        <DetailsHeader>
          <Name>{name}</Name>
          <div>
            Age {age} | {job}
          </div>
        </DetailsHeader>
        <DetailsGrid>
          {Object.keys(details).map(detail => (
            <div key={detail}>
              <DetailTitle>
                <img src={icons[detail]} alt={detail} />
                {detail.toUpperCase()}
              </DetailTitle>
              <div>{details[detail]}</div>
            </div>
          ))}
        </DetailsGrid>
      </Details>
    </Container>
  )
}

Persona.propTypes = {
  name: PropTypes.string.isRequired,
  age: PropTypes.number.isRequired,
  job: PropTypes.string.isRequired,
  says: PropTypes.string.isRequired,
  thinks: PropTypes.string.isRequired,
  feels: PropTypes.string.isRequired,
  does: PropTypes.string.isRequired,
  imageUrl: PropTypes.object.isRequired,
}

export default Persona
