import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { colors, screens } from '../../tailwind'

// Components
import Menu from '../components/Menu'
import Layout from '../components/Layout'
import TagList from '../components/TagList'
import Persona from '../components/Persona'
import Footer from '../components/Footer'

// Elements
import {
  ProjectInner,
  HeaderBigBox,
  BigBox,
  ImageCaption,
  ImageContainer,
  ImageContainerFull,
  ProjectHeader,
  ProjectDescription,
  ProjectDate,
  ProjectTitle,
  ProjectRow,
  ProjectRowPersona,
  ProjectSection,
  ProjectSectionTitle,
  ProjectSectionText,
  ProjectCallout,
} from '../elements/Projects'
import { StyledLinkOut } from '../elements/Links'
import NextProject from '../components/NextProject'

import MissionGif from '../images/scholarjet/scholarjet-mission.gif'
import ScholarJetDemoVideo from '../videos/scholarjet/scholarjet-demo.mp4'

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 84%;
  transform: translate(-44%, -68%);
`

const ImageContainerOffset = styled(ImageContainer)`
  margin-bottom: 2rem;

  @media screen and (min-width: ${screens.md}) {
    transform: translate(${props => props.translate});
    margin-bottom: 0;
  }
`

const theme = {
  menu: colors.black,
  menuHover: colors.white,
  accent: colors['am-yellow'],
  ally: colors['am-yellow-ally'],
}

const Page = ({ data }) => (
  <ThemeProvider theme={theme}>
    <Layout>
      <Menu color={colors.white} />
      <HeaderBigBox>
        <ProjectInner>
          <ImageContainer>
            <Img fluid={data.ScholarJet.childImageSharp.fluid} />
          </ImageContainer>
        </ProjectInner>
      </HeaderBigBox>
      <ProjectInner>
        <ProjectHeader>
          <ProjectDate>Fall 2017</ProjectDate>
          <ProjectTitle>ScholarJet</ProjectTitle>
          <ProjectDescription>
            Re-imagine a new brand identity to motivate students to think about their future.
          </ProjectDescription>
          <TagList tags={['Project Management', 'Responsive Web']} />
        </ProjectHeader>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>My contribution</ProjectSectionTitle>
            <ProjectSectionText>
              I took on the role as a project manager on a team with one UX designer, one visual designer, and one
              front-end developer. The team was made up of members from{' '}
              <StyledLinkOut href="https://web.northeastern.edu/scout/" target="__blank" rel="noopener noreferrer">
                Scout
              </StyledLinkOut>
              , a student-led design firm that pairs up young designers with entrepreneurs. We had three stakeholders,
              the two founders of ScholarJet and their CTO. Together, we spent roughly 10 hours a week for four months
              rebuilding their brand and improving their website.
            </ProjectSectionText>
          </ProjectSection>
          <ProjectSection>
            <ImageContainer>
              <Img fluid={data.founders.childImageSharp.fluid} />
            </ImageContainer>
            <ImageCaption>From left to right: Joe (co-founder), Tuan (co-founder) and Francisco (CTO)</ImageCaption>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ProjectSection>
            <ImageContainerOffset translate="-15%, -5%">
              <Img fluid={data.research.childImageSharp.fluid} />
            </ImageContainerOffset>
            <ImageContainerOffset translate="5%, 5%">
              <Img fluid={data.discovery.childImageSharp.fluid} />
            </ImageContainerOffset>
          </ProjectSection>
          <ProjectSection>
            <ProjectSectionTitle>Research and discovery</ProjectSectionTitle>
            <ProjectSectionText>
              We started by hearing from the founder, Tuan, about his passion for the company.{' '}
              <StyledLinkOut
                href="https://www.facebook.com/watch/?v=1889709321062280"
                target="__blank"
                rel="noopener noreferrer"
              >
                His story
              </StyledLinkOut>{' '}
              gave us insight into what was important from their existing brand identity and what needed to be elevated.
              Our stakeholders had a lot of hopes and dreams for our engagement with them. I facilitated an exercise
              whereby we gathered all their expectations, prioritized them, and got an agreement on a roadmap to move
              forward. The team would deliver brand guidelines, which included a new logo and illustrations, updated
              pages on their existing website, photography and videography content, and marketing materials.
            </ProjectSectionText>
            <ProjectSectionText>
              We also established this question to align the team to move forward:
            </ProjectSectionText>
            <ProjectCallout>
              How might we get students to apply for college scholarships during highschool?
            </ProjectCallout>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Lay of the land</ProjectSectionTitle>
            <ProjectSectionText>
              Before jumping into user research, I worked with Francisco to establish a sitemap and determine which
              pages in the existing site needed work. Because of the limited timeframe for development on our end, we
              split the work between our developer and their team.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
        <ProjectRowPersona>
          <Persona
            name="Angie"
            age={17}
            job="High-school Student"
            says="“I’m not sure how college scholarships work.”"
            thinks="That music and dance are her outlet."
            feels="Like a burden on her parents."
            does="DJ’s, goes to shows, learns about pop culture."
            imageUrl={data.angela.childImageSharp.fixed}
          />
        </ProjectRowPersona>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Understanding our users</ProjectSectionTitle>
            <ProjectSectionText>
              Our UX designer took the lead in doing field research at local high schools in Massachusetts. He was able
              to capture insights from informal conversations with many students who fit the target persona of
              ScholarJet. Together, the team gathered this data and summarized the findings into a persona named Angie.
            </ProjectSectionText>
            <ProjectSectionText>
              Angie is a junior in high school with hopes of affording college. Although her parents can help afford
              some of the cost, she doesn’t want to be a burden to her family. She’s unsure of how much college will
              cost and is overwhelmed at the thought of writing lots of essays for scholarships. She loves music and is
              learning to DJ on the side.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ImageContainer>
            <Img fluid={data.logo.childImageSharp.fluid} />
            <ImageCaption>Top: logo concepts Bottom: final logo</ImageCaption>
          </ImageContainer>
        </ProjectRow>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Building the Brand</ProjectSectionTitle>
            <ProjectSectionText>
              Our visual designer began by tackling the logo for ScholarJet. After learning more about the name
              (ScholarJet blasts you into the future, faster than a scholarship) she focused on education and airplanes
              as imagery. We worked together to communicate her ideas to our stakeholders, and her work totally won them
              over. She extended the branding with fun visual elements that could be added to both their web and print
              marketing materials.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
      </ProjectInner>
      <ImageContainerFull>
        <Img fluid={data.group.childImageSharp.fluid} />
      </ImageContainerFull>
      <ProjectInner>
        <ImageCaption>
          Joe, Tuan and Francisco celebrate with students who recently won scholarships. Photo By{' '}
          <StyledLinkOut href="https://www.lmichelephotography.com/" target="_blank" rel="noopener noreferrer">
            Lindsey Michelle
          </StyledLinkOut>
        </ImageCaption>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Believing in the mission</ProjectSectionTitle>
            <ProjectSectionText>
              Throughout this design process, Tuan and Joe were actively seeking their own funding for the venture. We
              loved being around such inspirational clients, and attended in their pitches for funding. I’ll never
              forget seeing Tuan do a celebratory backflip (two days after getting his wisdom teeth out) after winning
              both the judges and people's choice award.
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
        <ProjectRow>
          <ImageContainer>
            <img src={MissionGif} alt="The Mission" />
          </ImageContainer>
        </ProjectRow>
        <ProjectRow>
          <ProjectSection>
            <ProjectSectionTitle>Delivering on our promise</ProjectSectionTitle>
            <ProjectSectionText>
              Leading this team of rockstars led me to learn a few lessons. First,{' '}
              <strong>trust that your team has your back</strong>. My team kicked it out of the ballpark from the
              beginning research phase to the end with our development handoff. I created a cadence and let the team do
              the rest! Second, <strong>manage expectations early and often</strong>. Our clients were so eager to do it
              all. I had to negotiate what the team could deliver to ensure the design team was capable of handling the
              work. In this case, we learned to communicate openly and honestly (which came in handy around finals time
              because our design team delivered early). Lastly,{' '}
              <strong>working for a product you believe in will give you the strength to go above and beyond</strong>.
              My team ended up delivering on more than we promised because we really believed in the ScholarJet team.
              I’m so excited that they are still going strong! Check out{' '}
              <StyledLinkOut href="https://scholarjet.com/" target="__blank">
                their website
              </StyledLinkOut>
              .
            </ProjectSectionText>
            <ProjectSectionText>
              Special thanks to my design team{' '}
              <StyledLinkOut href="https://www.linkedin.com/in/mogodin/" target="__blank" rel="noopener noreferrer">
                Mo Godin
              </StyledLinkOut>{' '}
              (UX),{' '}
              <StyledLinkOut
                href="https://www.linkedin.com/in/mckennashuster/"
                target="__blank"
                rel="noopener noreferrer"
              >
                Mckenna Shuster
              </StyledLinkOut>{' '}
              (visual), and{' '}
              <StyledLinkOut
                href="https://www.linkedin.com/in/willslotterback/"
                target="__blank"
                rel="noopener noreferrer"
              >
                Will Slotterback
              </StyledLinkOut>
              (development) for making this project possible!
            </ProjectSectionText>
          </ProjectSection>
        </ProjectRow>
      </ProjectInner>
      <BigBox>
        <ImageContainer style={{ padding: '4rem 0' }}>
          <Img fluid={data.imac.childImageSharp.fluid} />
          <Video autoPlay muted loop controls playsinline>
            <track kind="captions" />
            <source src={ScholarJetDemoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </ImageContainer>
      </BigBox>
      <NextProject to="/search" bg={colors['am-green']} color={colors['am-green-ally']}>
        Platform-wide search
      </NextProject>
      <Footer />
    </Layout>
  </ThemeProvider>
)

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query {
    ScholarJet: file(relativePath: { eq: "projects/scholarjet.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    founders: file(relativePath: { eq: "scholarjet/scholarjet-founders.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    angela: file(relativePath: { eq: "scholarjet/scholarjet-angela.png" }) {
      childImageSharp {
        fixed(width: 177, height: 321) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    group: file(relativePath: { eq: "scholarjet/scholarjet-group.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    discovery: file(relativePath: { eq: "scholarjet/scholarjet-discovery.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logo: file(relativePath: { eq: "scholarjet/scholarjet-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    research: file(relativePath: { eq: "scholarjet/scholarjet-research.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1980) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imac: file(relativePath: { eq: "massart/massart-imac.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
